import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';

import { take } from 'rxjs/operators';

import {
    ConfigurationDataService,
    EventClickType,
    FeatureToggleDataService,
    enumFeatureToggle,
    IAccountInfo,
    IUpdateMyAccountRequest,
    JwtSessionService,
    NativeEventEnum,
    PlatformService,
    TranslateService,
    ValidationService,
} from '@cdux/ng-common';
import { ENVIRONMENT } from '@cdux/ng-core';
import { LoadingService, LoadingDotsComponent, ZendeskChatService } from '@cdux/ng-fragments';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { CduxSidebarContentComponent } from 'app/shared/sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { enumManageAccountType } from '../enums/manage-account.enum';
import { ManageBusinessService } from '../services/manage.business.service';
import { MatchingFieldValidator } from '../validator/matching-field.validator';
import { ValidatePin } from '../validator/pin.validator';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
  selector: 'cdux-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {
    public readonly AFFILIATE_KEY = this._environment.affiliateId.toString();
    public readonly CHAT_LINK_STYLE = 'chat-link';
    public eventClickType = EventClickType;

    private _isSmallGlass: boolean;
    private _routerSubscription: Subscription;

    private readonly EMAIL_VALIDATIONS = [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(128),
        Validators.pattern(ValidationService.emailRegex)];

    private readonly PASSWORD_VALIDATIONS = [
        Validators.required];


    private readonly PIN_VALIDATIONS = [
        Validators.required,
        Validators.pattern(ValidationService.ssn4RegEx),
        Validators.minLength(4),
        Validators.maxLength(4),
        ValidatePin];

    public form: UntypedFormGroup;
    public manageAccountType = enumManageAccountType;
    public successMessage: string = '';
    public editType: enumManageAccountType;
    public readonly phoneMask = ValidationService.cellphoneMask;
    public accountInfo: IAccountInfo = <IAccountInfo>{};
    public loadingDotsComponent = LoadingDotsComponent;
    public isLoaded: boolean = false;
    public customError: string = '';
    public zendeskEnabled: boolean;
    public phoneSupportEnabled: boolean;
    public showChatMessage: boolean;
    public editAddressEnabled: boolean;
    public ssoLoginFT: boolean = false;
    public isNative: boolean;
    public displayPinInput: boolean;
    public siteName: string;

    /*********************************
     * SidbarComponent Implementation
     *********************************/
    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: ManageComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            }
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            translateKey: 'manage-title',
            translateLanguage: 'my-account',
            eventClickType: EventClickType.MY_ACCOUNT_SETTINGS_BACK_BUTTON
        };
    }

    public setProperties(properties: ISidebarComponentProperties): void {
    }

    public editAccount(manageAccountType: enumManageAccountType) {
        console.error('editAccount Type:', manageAccountType); //TODO remove after test
        if (manageAccountType == enumManageAccountType.ADDRESS) return; //TODO remove after test

        this.editType = manageAccountType;
        this._initializeForm();
    }

    constructor(
        private _accountService: ManageBusinessService,
        protected configService: ConfigurationDataService,
        private _environment: ENVIRONMENT,
        private _fb: UntypedFormBuilder,
        private _loadingService: LoadingService,
        private _mediaQuery: CduxMediaToggleService,
        private _platformService: PlatformService,
        private _router: Router,
        public _sessionService: JwtSessionService,
        private _translateService: TranslateService,
        private _zendeskChatService: ZendeskChatService,
        private _featureToggleDataService: FeatureToggleDataService
    ) {
        super();
        this._isSmallGlass = this._mediaQuery.query('phone');
    }

    ngOnInit() {
        this._loadingService.register('myAccountContainer');
        this._initializeForm();
        this._accountService.load().pipe(
            take(1)
        ).subscribe( (accountInfo: IAccountInfo) => {
            if (!!accountInfo) {
                this.accountInfo = accountInfo;
                this.form.get('emailGroup').get('email').setValue(accountInfo.email);
                this.form.get('phoneGroup').get('phone').setValue(accountInfo.phone);
                this.isLoaded = true;
                this._loadingService.resolve('myAccountContainer', 0, 'success');
                this._platformService.sendMessage(NativeEventEnum.TUX_LOADED);
            } else {
                this._platformService.sendMessage(NativeEventEnum.ERROR);
            }
        });
        this.zendeskEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.ZENDESK_CHAT);
        this.phoneSupportEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this.ssoLoginFT = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.ENABLE_SSO_LOGIN);
        this.editAddressEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.SHOW_EDIT_ADDR);
        // hide chat bubble on SG to avoid blocking PIN edit button
         if (this._isSmallGlass) this._zendeskChatService.setMessengerVisibility(false);
         // subscribe to router events to hide zendesk chat when on restricted routes
        this._routerSubscription = this._router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this._zendeskChatService.isRestrictedRoute(event.url)) {
                    this._zendeskChatService.setMessengerVisibility(false);
                } else {
                    this._zendeskChatService.setMessengerVisibility(true);
                }
            }
        });
        this.isNative = this._platformService.isNativeApp();
        this.displayPinInput = this._displayPinInput();
        this.siteName = this._translateService.translate('sso-affiliate-name', this.AFFILIATE_KEY);
    }

    ngOnDestroy(): void {
        if (this._routerSubscription) this._routerSubscription.unsubscribe();
    }

    public onFormUpdate(type: enumManageAccountType): void {
        const data: IUpdateMyAccountRequest = {};
        let formGroup: AbstractControl;

        switch (type) {
            case enumManageAccountType.EMAIL:
                formGroup = this.form.get('emailGroup');
                data.email = this.form.value.emailGroup.newEmail;
                data.password = this.form.value.emailGroup.emailPassword;
                break;
            case enumManageAccountType.PASSWORD:
                formGroup = this.form.get('passwordGroup');
                data.currentPassword = this.form.value.passwordGroup.currentPassword;
                data.password = this.form.value.passwordGroup.newPassword;
                break;
            case enumManageAccountType.CELL_NUMBER:
                formGroup = this.form.get('phoneGroup');
                data.phone = this.form.value.phoneGroup.phone;
                if (data.phone) {data.phone = data.phone.replace(/[^0-9]/g, ''); }// Remove non-numeric values
                break;
            case enumManageAccountType.PIN:
                if(!this.displayPinInput) {
                    return;
                }
                formGroup = this.form.get('pinGroup');
                data.currentPin = this.form.value.pinGroup.currentPin;
                data.pin = this.form.value.pinGroup.newPin;
                break;
        }

        if (!!formGroup && formGroup.valid) {
            this._loadingService.register('myAccountContainer');
            this.isLoaded = false;

            this._accountService.update(type, data).pipe(
                take(1)
            ).subscribe( result => {
                if (result && result.info) {
                    this.successMessage = result.message;
                    this.editType = null;

                    // Update the local values from the result object
                    if (result.info.phone) {
                        this.accountInfo.phone = result.info.phone;
                        this.form.get('phoneGroup').patchValue({phone: result.info.phone});
                    }
                } else {
                    // Handle error: the result.message may contain two specific password errors(other error has not
                    // actively handld yet): if user trying to reuse current or previous password
                    // if the backend determines password is invalid format
                    this.customError = this.phoneSupportEnabled ? this._translateService.translate(
                        'error-generic',
                        'my-account',
                        false,
                        this._translateService.translate('primary-phone-number', this.AFFILIATE_KEY)) : 'error-generic-chat';
                    if (result && result.message) {
                        const searchErrorPasswordReuse: string = this._translateService.translate('error-password-reuse-search-str', 'my-account');
                        const searchErrorPasswordInvalid: string = this._translateService.translate('error-password-invalid-search-str', 'my-account');
                        if (result.message.toLowerCase() === searchErrorPasswordReuse.toLowerCase()) {
                            this.customError = this._translateService.translate('error-password-reuse-display', 'my-account');
                        } else if (result.message.toLowerCase() === searchErrorPasswordInvalid.toLowerCase()) {
                            this.customError = this._translateService.translate('error-password-invalid-display', 'my-account');
                        }
                    }
                    this.showChatMessage = !this.phoneSupportEnabled && this.customError.includes('-chat');
                }

                this._loadingService.resolve('myAccountContainer', 0, 'success');
                this.isLoaded = true;

                // Clear the message after 5 seconds
                setTimeout(() => {
                    this.successMessage = '';
                    this.customError = '';
                }, 5000);
            });
        }
    }

    public formatPhone(phone: string): string {
        if (phone.length !== 10) {
            return phone;
        }
        return '(' + phone.substring(0, 3) + ') ' +  phone.substring(3, 6) + '-' + phone.substring(6, 10);
    }

    public cancel(): void {
        this._initializeForm();
        this.editType = null;
    }

    public launchZeMessenger() {
        if (this.zendeskEnabled && this._zendeskChatService.isLoaded()) {
            this._zendeskChatService.setMessengerVisibility(true);
            this._zendeskChatService.openZeMessenger();
        }
    }

    private _displayPinInput(): boolean {
        const hidePin = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.HIDE_PIN);
        const isIOS = this._platformService.isIOS();
        if (hidePin || isIOS) {
            return false;
        }
        return true
    }

    private _initializeForm(): void {
        this.form = this._fb.group({
            emailGroup: this._fb.group({
                email: [this.accountInfo.email],
                newEmail: ['', this.EMAIL_VALIDATIONS],
                confirmEmail: ['', this.EMAIL_VALIDATIONS],
                emailPassword: ['', this.PASSWORD_VALIDATIONS]
            }, {
                    validator: MatchingFieldValidator.createValidator('newEmail', 'confirmEmail')
                }),

            passwordGroup: this._fb.group({
                currentPassword: ['', this.PASSWORD_VALIDATIONS],
                newPassword: ['', this.PASSWORD_VALIDATIONS],
                confirmPassword: ['', this.PASSWORD_VALIDATIONS]
            }, {
                validator: MatchingFieldValidator.createValidator( 'newPassword', 'confirmPassword', true)
            }),

            phoneGroup: this._fb.group({
                phone: [this.accountInfo.phone, [
                    Validators.required,
                    Validators.pattern(ValidationService.phoneRegEx)]
                ],
            }),
            pinGroup: this._fb.group({
                pin: ['    '],
                newPin: ['', this.PIN_VALIDATIONS],
                confirmPin: ['', this.PIN_VALIDATIONS],
                currentPin: ['', this.PIN_VALIDATIONS]
            }, {
                    validator: MatchingFieldValidator.createValidator( 'newPin', 'confirmPin')
            })
        });
    }
}
