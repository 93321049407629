import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import {
    Subject,
    Subscription
} from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import {
    Bet,
    BetAmountType,
    BetCalculatorBusinessService,
    enumFeatureToggle,
    enumTrackType,
    EventClickType,
    FeatureToggleDataService,
    IBetResult,
    ISelectedBetAmount,
    ISelectedEntry,
    IWagerValidation,
    PoolType,
    RunnerListConfig,
    TranslateService,
    WagerService,
    OldWagerValidationService,
    JwtSessionService
} from '@cdux/ng-common';
import {  CduxStorageService } from '@cdux/ng-platform/web';

import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { SsnCollectionService } from '../../../ssn-collection/services/ssn-collection.service';
import { BET_ERROR_CODES } from '../../enums/bet-error-codes.enum';
import { WAGER_CONDITIONS } from '../../enums/conditional-wagering.enum';
import { IBetError } from '../../interfaces/bet-error.interface';
import { IBetShareTotals } from '../../interfaces/bet-share-totals';
import { BetSlipErrorsService } from '../../services/bet-slip-errors.service';
import { BetSlipBusinessService } from '../../services/bet-slip.business.service';
import { BetsBusinessService } from '../../services/bets.business.service';
import { BetShareBusinessService } from '../../services/betshare.business.service';
import { FundingService } from './../../../funding/shared/services/funding.service';
import { ConditionalWageringBusinessService } from './../../services/conditional-wagering.business.service';
import { FundingDepositOptionsComponent } from 'app/shared/funding/components/deposit-options';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { IBetShareConfig } from '@cdux/ng-fragments';

@Component({
    selector: 'cdux-bet',
    templateUrl: './bet.component.html',
    styleUrls: ['./bet.component.scss'],
})
export class BetComponent implements OnInit, OnDestroy {

    private static readonly HIDE_TOOLTIP = 'hideTooltip';
    private static readonly SUCCESS_ANIMATION_DURATION = 2500;

    @Input() bet: Bet;
    @Input() externallySubmitted: boolean;
    @Input() forceSubmitAnimation: boolean;
    @Input() showSSNInput: boolean;
    @Input() forceSSNInput: boolean;
    @Input() clearFundingError: boolean;
    @Input() betShareEnabled: boolean;
    @Input() conditionalWageringEnabled: boolean;
    @Input() index: number;
    @Input() mtpConfig: number;
    @Input() showSaddleCloths: boolean;

    private _programView: enumProgramViews;
    @Input()
    public set programView(value: enumProgramViews) {
        this._programView = value;
        this.isBetPad = (value === enumProgramViews.BETPAD);
    }
    public get programView(): enumProgramViews {
        return this._programView;
    }

    public depositButtonText: string;

    @Output() updateTempBetShares: EventEmitter<{bet: Bet, store: boolean, callback?: any}> = new EventEmitter<{bet: Bet, store: boolean, callback?: any}>();
    @Output() displaySsnCollection: EventEmitter<{betId: string, display: boolean}> = new EventEmitter<{betId: string, display: boolean}>();
    @Output() submissionInProgress: EventEmitter<{betId: string, submitting: boolean}> = new EventEmitter<{betId: string, submitting: boolean}>();

    /**
     * Exposes the event click types to the template.
     *
     * @type {EventClickType}
     */
    public eventClickType = EventClickType;

    public isShrunk: boolean = false;
    public total: string;
    public betType: PoolType;
    public betTypeName = '';
    public submitSuccess = false;
    public betSubtypeName = '';
    public allowsBetShare: boolean = true;
    public showBetShareTooltip: boolean;
    public showBetShareSuccess: boolean = false;
    public betShareTotals: IBetShareTotals;
    public isButtonDisabled: boolean = false;
    public showBetShareMTPRestriction: boolean = false;
    public showOdds: boolean;
    public MTP_RESTRICTION = BET_ERROR_CODES.BETSHARE_MTP_RESTRICTION;
    public INSUFFICIENT_FUNDS = BET_ERROR_CODES.INSUFFICIENT_FUNDS;
    public WAGER_CONDITIONS = WAGER_CONDITIONS;
    public isBetPad: boolean = false;
    public runnerListConfig: RunnerListConfig;
    public ftFullPageDeposit = false;
    public newBetShareBetSlipView = false; // this need to be removed once the new view is fully implemented.
    private sharesInput$ = new Subject<string>();
    private reserveSharesInput$ = new Subject<string>();
    public MAXIMUM_SHARES = BET_ERROR_CODES.MAXIMUM_SHARES;

    public BET_PAD_FUNDING_FEATURE_ENABLED = false;

    public get betError(): IBetError {
        if (this.bet) {
            return this._betSlipErrorsService.getError(this.bet.id)
        }
        return null;
    }

    private _amountIndex: number;
    private _amountUpdateLocked: boolean = false;

    private _subscriptions: Subscription[] = [];
    private _betShareConfig: IBetShareConfig;

    // Subject used to trigger subscription cleanup
    private _destroy: Subject<boolean> = new Subject();

    constructor(
        private _betCalculator: BetCalculatorBusinessService,
        private _betSlipErrorsService: BetSlipErrorsService,
        private _betsBusinessService: BetsBusinessService,
        private _betSlipService: BetSlipBusinessService,
        private _betShareService: BetShareBusinessService,
        private _cduxStorageService: CduxStorageService,
        private _router: Router,
        private _changeDetector: ChangeDetectorRef,
        private _sidebarService: SidebarService,
        private _translateService: TranslateService,
        private _wagerService: WagerService,
        private _OldWagerValidationService: OldWagerValidationService,
        private _sessionService: JwtSessionService,
        private _ssnCollectionService: SsnCollectionService,
        private _featureToggleService: FeatureToggleDataService,
        private _conditionalWageringService: ConditionalWageringBusinessService,
        private _fundingService: FundingService,
        private _betShareBusinessService: BetShareBusinessService,
        private localFeatureToggleService: FeatureToggleDataService,
    ) {
        this.depositButtonText = this._translateService.translate('deposit-text', 'wagers');
        this.ftFullPageDeposit = localFeatureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
    }

    public ngOnInit() {
        this.newBetShareBetSlipView = this.localFeatureToggleService.isFeatureToggleOn('BETSHARE_MOD') && this.betShareEnabled;
        this.betType = this.bet.poolType && PoolType.getPoolTypeByCode(this.bet.poolType.Code);
        const subType = this._wagerService.getBetSubTypesForPool(this.bet.poolType).filter((e) => {
            return e.value === this.bet.betSubtype;
        })[0]?.label;
        this.betTypeName = this.betType ? this.betType.code.toUpperCase() : '';
        this.betSubtypeName = subType === 'Straight' || subType === 'Wheel' ? '' : ' ' + subType;
        this.total = this._betCalculator.calculate(this.bet).toString();
        this.runnerListConfig = {
            subtype: this.bet.betSubtype || null,
            selectionCount: this.betType && this.betType.raceLegs || 1,
            bettingInterests: this.bet.runners.map<ISelectedEntry[]>((leg) =>
                leg.map<ISelectedEntry>((entry) => ({
                    ProgramNumber: entry.ProgramNumber,
                    BettingInterest: +entry.ProgramNumberCoupled
                }))
            ),
            alternateBettingInterest: this.bet.alternateBettingInterests

        };

        if (this.bet.betShare) {
            this.betShareTotals = this._betShareService.calculateBetShareTotals(this.bet.shares.totalShares, this.bet.shares.reserveShares , +this.total);
        }

        if (this.bet.isSubmittedWager) {
            this.showBetShareSuccess = true;
        }
        this._betShareBusinessService.getBetShareConfig()
            .subscribe({
                next: (config: IBetShareConfig) => {
                    this._betShareConfig = config;
                }
            })
        this.sharesInput$.pipe(
            debounceTime(250),
            takeUntil(this._destroy)
        ).subscribe(inputValue => {
            const parsedShares = parseInt(inputValue, 10);
            if (parsedShares > this._betShareConfig.maxShare) {
                return;
            }
            if (this._betShareService.validateTotalShares(this.bet, parsedShares, +this.total)) {
                this.bet.shares.totalShares = parsedShares;
                
                if (this._betShareService.validateReserveShares(this.bet, this.bet.shares.reserveShares)) {
                    this._cduxStorageService.store(this.bet);
                }
            } else {
                this.bet.shares.totalShares = parsedShares;
            }
        });
        this.reserveSharesInput$.pipe(
            debounceTime(250),
            takeUntil(this._destroy)
        ).subscribe(inputValue => {
            const parsedShares = parseInt(inputValue, 10);
            if (this._betShareService.validateReserveShares(this.bet, parsedShares)) {
                this.bet.shares.reserveShares = parsedShares;
                if (this._betShareService.validateReserveShares(this.bet, this.bet.shares.reserveShares)) {
                    this._cduxStorageService.store(this.bet);
                }
            }
        });

        // as we added custom bet amount so we are adding index as closest amount's index
        if (this.bet.amount.type === BetAmountType.CUSTOM) {
            const customAmount = this.bet.amount.value;
            const closest = this.bet.allowedAmounts.reduce(function(prev, curr) {
                return (Math.abs(+curr.value - +customAmount) < Math.abs(+prev.value - +customAmount) ? curr : prev);
            });
            this._amountIndex = this._betSlipService.findSelectedAmount(this.bet.allowedAmounts, closest);
        }

        this.isShrunk = this._betSlipService.shouldShrink(this.bet);
        if (this.isShrunk) {
            // have to remove isShrunk class after some time as we are doing some animation if bet is copied from active bets section.
            setTimeout(() => {
                this.isShrunk = false;
            }, 1000);
        }

        // US25792: temporarily disable betshare for greyhound
        if (this.betShareEnabled && this.bet.track.TrackType === enumTrackType.GREY) {
            this.betShareEnabled = !this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BETSHARE_DISABLE_GH);
        }
        // US36276: disable BetShare on Future/Advance wagers:
        this.allowsBetShare = this.bet.track.AllowsBetShareWagering === true;

        // To hide the bet share tool tip when bet is a conditional.
        if (this.bet.conditional) {
            this.showBetShareTooltip = false;
        } else {
            this.showBetShareTooltip = this.betShareEnabled && this.index === 0 && !localStorage.getItem(BetComponent.HIDE_TOOLTIP);
        }

        this.conditionalWageringEnabled = this.conditionalWageringEnabled && this.bet.track.AllowsConditionalWagering && this._conditionalWageringService.isValidRaceType(this.bet.poolType, this.bet.runners);
        this.showOdds = this._conditionalWageringService.showOdds(this.bet.poolType);

        // If this is from BetPad, then check BETPAD toggle and BETPAD_FUNDING toggle status:
        if (this.isBetPad) {
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETPAD_FUNDING).pipe(
                takeUntil(this._destroy)
            ).subscribe((fundingFT) => {
                this.BET_PAD_FUNDING_FEATURE_ENABLED = fundingFT;
            });
        }

        if(this.clearFundingError){
            this._betSlipErrorsService.removeError(this.bet.id);
        }
        this._amountIndex = this._betSlipService.findSelectedAmount(this.bet.allowedAmounts, this.bet.amount);
    }

    public ngOnDestroy() {
        this._subscriptions.map((subscription: Subscription) => {
            subscription.unsubscribe();
        });

        // Trigger subscription cleanup
        this._destroy.next();
        this._destroy.complete();
    }

    /**
     * Increment the bet amount to the next allowed amount.
     */
    public increaseAmount() {
        if (!this._amountUpdateLocked && this._amountIndex + 1 < this.bet.allowedAmounts.length) {
            this._updateBetAmount(this.bet.allowedAmounts[this._amountIndex + 1]);
        }
    }

    /**
     * Decrement the bet amount to the next lower allowed amount.
     */
    public decreaseAmount() {
        if (!this._amountUpdateLocked && this._amountIndex - 1 >= 0) {
            // as we added custom bet amount so we are adding index as closest amount's index
           if (this._betSlipService.findSelectedAmount(this.bet.allowedAmounts, this.bet.amount) === -1) {
               this._updateBetAmount(this.bet.allowedAmounts[this._amountIndex])
           } else {
               this._updateBetAmount(this.bet.allowedAmounts[this._amountIndex - 1])
           }
        }
    }

    /**
     * Handles updates the amount of a bet, and updates the storage service to reflect the change.
     *
     * @param {string} amount
     */
    public _updateBetAmount(amount: ISelectedBetAmount) {
        // Let bet amount updating so that I don't start queueing up pointless changes.

        this._amountUpdateLocked = true;

        // I want a temporary bet so that the bet amount doesn't update in the UI before
        // the stored bet is updated.
        const tempBet = Bet.fromBetlike(this.bet);
        tempBet.amount = amount;

        if (tempBet.betShare) {
            tempBet.cost = this._betCalculator.calculate(tempBet).toString();
            // finding if betshare min value is meet or not
            const isMinBetShareValue = this._betShareService.validateTotalShares(this.bet, this.bet.shares.totalShares, +tempBet.cost);
            if (!isMinBetShareValue) {
                this._amountUpdateLocked = false;
                return;
            }
        } else if (tempBet.conditional) {
            tempBet.cost = this._betCalculator.calculate(tempBet).toString();
        }

        this._cduxStorageService.store(tempBet)
            .then(() => {
                this.bet = tempBet;
                this._amountIndex = this._betSlipService.findSelectedAmount(this.bet.allowedAmounts, this.bet.amount);

                const currentError = this._betSlipErrorsService.getError(this.bet.id);
                if (currentError
                    && (
                        currentError.errorCode === BET_ERROR_CODES.MAX_EXCEEDED
                        || currentError.errorCode === BET_ERROR_CODES.MIN_NOT_MET
                    )) {
                    this._betSlipErrorsService.removeError(this.bet.id);
                }

                this._OldWagerValidationService.validateTotal(this.bet, undefined, false).subscribe({next: (validatedBet: IWagerValidation) => {
                    if (!validatedBet.isValid) {
                        if (!validatedBet.passesMaxCheck) {
                            this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MAX_EXCEEDED);
                        }
                        if (!validatedBet.passesMinCheck) {
                            this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MIN_NOT_MET);
                        }
                    }
                },
                error: (error) => {
                    console.error(error);
                }});

                this._amountUpdateLocked = false;
            })
            .catch(() => this._amountUpdateLocked = false);
    }

    /**
     * Takes the bet, and creates a new one that will rest adjacent in the bet order.
     */
    public copyBet() {
        if (this.betError && this.betError.disableCopy) {
            return;
        }

        const newBet = Bet.fromBetlike(this.bet);

        newBet.id = newBet.id + '--' + Math.round((new Date()).getTime() / 1000).toString();   // todo: Leave as milliseconds instead of converting to seconds, needs to be done in cdux-ng as well.
        newBet.betCreatedTimestamp = (newBet.betCreatedTimestamp - 1);
        this._cduxStorageService.store(newBet);
    }

    /**
     * This function emit's the stored bet that was clicked on to the BetSlipBusinessService.
     * Refer to the Program Component ngOnInit to see how this emitted bet is used.
     */
    public editBet() {
        if (this.betError && this.betError.disableEdit) {
            return;
        }

        this._betsBusinessService.removeScratchedHorses(this.bet).pipe(take(1)).subscribe((unScratchedBet: Bet) => {
            this._router.navigate(['/program', this.bet.track.UrlDisplayName, this.bet.track.BrisCode, this.bet.track.TrackType, this.bet.race.race])
                .then(() => {
                    this._betSlipService.currentBet = unScratchedBet;
                    this._sidebarService.close(true);
                });
        });
    }

    public deleteBet() {
        this._cduxStorageService.destroy(this.bet);
    }

    public submitBet(forceRegularBet: boolean = false) {
        if (this.externallySubmitted || (this.betError && (this.betError.isPermanent || this.betError.disableSubmit))) {
            return;
        }

        if (forceRegularBet) {
            this.bet.betShare = false;
        }

        this.toggleSubmissionInProgress(true, this.bet.id);
        this._betSlipErrorsService.removeError(this.bet.id);
        if (this.bet.betShare && this._sessionService.getUserInfo().cssdLength < 9) {
            // SSN COLLECTION FLOW
            this._changeSsnCollectionState(true);
            this._ssnCollectionService.ssnUpdated
                .pipe(
                    take(1)
                ).subscribe((ssnUpdated) => {
                    if (ssnUpdated === null) { // SSN cancelled emits null.
                        // CANCELLATION FLOW
                        this.bet.betShare = false;
                        this._cduxStorageService.store(this.bet);
                        this._changeSsnCollectionState(false);
                        this.toggleSubmissionInProgress(false, this.bet.id);
                    } else if (ssnUpdated) {
                        // SUCCESSFUL SSN COLLECTION FLOW
                        // Need To close the SSN component as SSN is updated
                        this._changeSsnCollectionState(false);
                        this.submitBet();
                    } else {
                        // FAILED SSN COLLECTION FLOW
                        this._changeSsnCollectionState(false);
                        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.BS_SSN);
                        this.toggleSubmissionInProgress(false, this.bet.id);
                    }
                });
        } else {
            this._betsBusinessService.submitWager(this.bet, this.total).subscribe(
                (wagerResult: IBetResult) => {
                    if (wagerResult && wagerResult.success) {
                        this.submitSuccess = true;
                        this._changeDetector.detectChanges();
                        if (!this.bet.betShare) {
                            setTimeout(() => {
                                this._cduxStorageService.destroy(this.bet).then(() => {
                                    this.toggleSubmissionInProgress(false, this.bet.id);
                                });
                            }, BetComponent.SUCCESS_ANIMATION_DURATION);
                        } else {
                            setTimeout(() => {
                                this.submitSuccess = false;
                                this.bet.isSubmittedWager = true;
                                this.bet.betShareId = wagerResult.message;
                                this.updateTempBetShares.emit({bet: Bet.fromBetlike(this.bet), store: true});
                                this._cduxStorageService.destroy(this.bet).then(() => {
                                    this.toggleSubmissionInProgress(false, this.bet.id);
                                });
                            }, BetComponent.SUCCESS_ANIMATION_DURATION);
                        }
                    } else if (wagerResult.message === BET_ERROR_CODES.BETSHARE_MTP_RESTRICTION.replace('%s', this.mtpConfig.toString())) {
                        this.showBetShareMTPRestriction = true;
                        this.bet.betShare = false;
                        wagerResult.message = BET_ERROR_CODES.BETSHARE_MTP_RESTRICTION.toString();
                        this._betSlipErrorsService.setError(wagerResult.wagerId, wagerResult.message);
                        this.toggleSubmissionInProgress(false, this.bet.id);
                    } else if (wagerResult.message === BET_ERROR_CODES.INSUFFICIENT_FUNDS
                              && this.isBetPad && !this.BET_PAD_FUNDING_FEATURE_ENABLED) {
                        wagerResult.message = BET_ERROR_CODES.INSUFFICIENT_FUNDS_NODEPOSIT;
                        this._betSlipErrorsService.setError(wagerResult.wagerId, wagerResult.message);
                        this.toggleSubmissionInProgress(false, this.bet.id);
                    } else {
                        this.toggleSubmissionInProgress(false, this.bet.id);
                    }
                },
                (err) => {
                    if (!this._betSlipErrorsService.getError(this.bet.id)) {
                        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.UNKNOWN_ERROR);
                    }
                    // Moved outside of if-block because we want the loader-dots on the
                    // individual Bet Slip ticket SUBMIT button removed if there's any error.
                    this.toggleSubmissionInProgress(false, this.bet.id);
                });
        }
    }

    public openDepositFlow() {
        if (this.betError.errorCode !== BET_ERROR_CODES.INSUFFICIENT_FUNDS) {
            return;
        }

        // If we're opening the deposit flow, then we need to store the bet
        // so that bet share information is kept around for the callback submission.
        this._cduxStorageService.store(this.bet);

        if (this.ftFullPageDeposit) {
            this._fundingService.postDepositRedirectURL = this._router.url.replace(/\?.*/, '');
            if (this.isBetPad) { // use returningBetId parameter to submit bet when using betpad
                this._fundingService.postDepositRedirectURL += `?returningBetId=${this.bet.id}`;
            } else { // append saved-bets action with bet id to submit bet using sidebar service
                this._fundingService.postDepositRedirectURL += `?action=saved-bets&inputs={"returningBetId":"${this.bet.id}"}`;
            }
            this._router.navigate(['/', 'deposit'], { queryParams: { betId: this.bet.id }});
        } else {
            this._sidebarService.loadComponent(FundingDepositOptionsComponent.getSidebarComponent({betId: this.bet.id}), FundingDepositOptionsComponent.getHeaderComponent(), {clearHistory: true}); // TODO temp comment out
        }

        this._fundingService.updateAccountBalance().pipe(take(1)).subscribe();
    }

    public hideTooltip() {
        localStorage.setItem(BetComponent.HIDE_TOOLTIP, 'true');
        this.showBetShareTooltip = false;
    }

    public openBetShare() {
        if (this.betShareEnabled) {
            this._updateIsBetShare();
        }
    }

    /**
     * Shows / Hides the SSN Collection widget.
     *
     * @private
     */
    private _changeSsnCollectionState(display: boolean) {
        this.displaySsnCollection.emit({betId: this.bet.id, display: display});
        // Setting this property to false is not overkill.
        // Since it uses observables, I don't want synchronous logic
        // that relies on this boolean to behave improperly because
        // the value hasn't yet propagated.
        this.showSSNInput = display;
    }

    private _updateIsBetShare(): void {
        const tempBet = Bet.fromBetlike(this.bet);
        tempBet.betShare = !tempBet.betShare;
        if (!tempBet.betShare) {
            this._betShareService.resetBetShare(tempBet);
            this._betSlipErrorsService.removeError(this.bet.id);
        } else {
            if (tempBet.conditional) {
                this._conditionalWageringService.clearConditionalWager(tempBet);
            }
            tempBet.cost = this._betCalculator.calculate(tempBet).toString();
            tempBet.shares = this._betShareService.getDefaultBetShare();
            
            // Validate initial share value
            if (!this.checkShareValue(tempBet.shares.totalShares)) {
                this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MINIMUM_SHARES_VALUE);
                return;
            }
        }
        this._cduxStorageService.store(tempBet);
    }

    public updateTotalShares(increment: boolean): void {
        const newShares = increment ? this.bet.shares.totalShares + 1 : this.bet.shares.totalShares - 1;
        // Check maximum shares
        if (newShares > this._betShareConfig.maxShare) {
            this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MAXIMUM_SHARES);
            return;
        }
        // Check share value
        if (!this.checkShareValue(newShares)) {
            this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MINIMUM_SHARES_VALUE);
            return;
        }
        if (this._betShareService.validateTotalShares(this.bet, newShares, +this.total)) {
            this.bet.shares.totalShares = newShares;
            this.betShareTotals = this._betShareService.calculateBetShareTotals(
                newShares,
                this.bet.shares.reserveShares,
                +this.total
            );
            this._betSlipErrorsService.removeError(this.bet.id);
            this._cduxStorageService.store(this.bet);
        }
    }

     /////////////////////////////////////////
    //start betshare split new input logic///
   /////////////////////////////////////////

   public updateTotalSharesFromInput(inputValue: string, event?: KeyboardEvent | FocusEvent): void {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
        return;
    }
    if (!inputValue) {
        return;
    }
    const sanitizedInput = inputValue.replace(/[^0-9]/g, '');
    const parsedShares = parseInt(sanitizedInput, 10);
    
    // Validate maximum shares
    if (parsedShares > this._betShareConfig.maxShare) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MAXIMUM_SHARES);
        return;
    }
    if (!this.checkShareValue(parsedShares)) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MINIMUM_SHARES_VALUE);
        return;
    }

    // Only update if validation passes
    this.bet.shares.totalShares = parsedShares;
    this._betSlipErrorsService.removeError(this.bet.id);
    
    this.sharesInput$.next(sanitizedInput);
}

private checkShareValue(shares: number): boolean {
    const betTotal = +this.total;
    const shareValue = Math.trunc((betTotal / shares) * 100) / 100;
    return shareValue >= 0.01;
}

public validateShares(): void {
    const shares = this.bet.shares.totalShares;
    const betTotal = +this.total;
    
    if (shares > this._betShareConfig.maxShare) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MAXIMUM_SHARES);
        return;
    }

    if (this.checkShareValue) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.MINIMUM_SHARES_VALUE);
        return;
    }

    this._betSlipErrorsService.removeError(this.bet.id);

    if (shares > 0) {
        if (this._betShareService.validateTotalShares(this.bet, shares, betTotal)) {
            if (this._betShareService.validateReserveShares(this.bet, this.bet.shares.reserveShares)) {
                this.betShareTotals = this._betShareService.calculateBetShareTotals(
                    shares,
                    this.bet.shares.reserveShares,
                    betTotal
                );
                this._cduxStorageService.store(this.bet);
            }
        }
    }
}

public updateReserveSharesFromInput(inputValue: string, event?: KeyboardEvent | FocusEvent): void {
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
        return;
    }
    if (!inputValue) {
        return;
    }
    if (/[^0-9]/.test(inputValue)) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.INVALID_RESERVE_AMOUNT);
        return;
    }
    const parsedShares = parseInt(inputValue, 10);
    if (parsedShares === 0) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.INVALID_RESERVE_MIN);
        return;
    }
    // Check if reserve shares exceed total shares
    if (parsedShares > this.bet.shares.totalShares) {
        this._betSlipErrorsService.setError(this.bet.id, BET_ERROR_CODES.INVALID_RESERVE_MAX);
        return;
    }
    // Only update if all validation passes
    this.bet.shares.reserveShares = parsedShares;
    this._cduxStorageService.store(this.bet)
    this._betSlipErrorsService.removeError(this.bet.id);
    this.reserveSharesInput$.next(inputValue);
}

     //////////////////////////////////////
    ////////end new betshare input////////
   //////////////////////////////////////

    public updateReserveShares(increment: boolean): void {
        const shares = increment ? this.bet.shares.reserveShares + 1 : this.bet.shares.reserveShares - 1;
        if (this._betShareService.validateReserveShares(this.bet, shares)) {
            this.bet.shares.reserveShares = shares;
            this._cduxStorageService.store(this.bet);
        }
    }

    public closeShareBetSuccessPanel() {
        this.updateTempBetShares.emit({bet: this.bet, store: false, callback: () => {
            this._cduxStorageService.destroy(this.bet);
        }});
    }

    public toggleConditionalWager(): void {
        if (this.bet.conditional) {
            this._conditionalWageringService.clearConditionalWager(this.bet);
        } else {
            if (this.bet.betShare) {
                this._betShareService.resetBetShare(this.bet);
            }
            this._conditionalWageringService.initializeConditionalWager(this.bet);
        }
        this._cduxStorageService.store(this.bet);
    }

    public updateConditionalWager(increment: boolean, condition: WAGER_CONDITIONS): void {
        if (!this._amountUpdateLocked) {
            this._amountUpdateLocked = true;
            const tempBet = Bet.fromBetlike(this.bet);
            switch (condition) {
                case WAGER_CONDITIONS.MTP:
                    tempBet.conditionalMtp = this._conditionalWageringService.updateMTP(tempBet.conditionalMtp, increment);
                    break;
                case WAGER_CONDITIONS.PAYOUT:
                    tempBet.conditionalProbablePayout = this._conditionalWageringService.updatePayout(tempBet.conditionalProbablePayout, increment);
                    break;
                case WAGER_CONDITIONS.ODDS:
                    tempBet.conditionalOdds = this._conditionalWageringService.updateOdds(tempBet.conditionalOdds, increment);
                    break;
            }
            this._cduxStorageService.store(tempBet).then(() => {
                this.bet = tempBet;
                this._amountUpdateLocked = false;
            }).catch(() => this._amountUpdateLocked = false);
        }
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this._updateBetAmount(betAmount);
    }

    private toggleSubmissionInProgress(submitting: boolean, id: string): void {
        this.isButtonDisabled = submitting;
        this.submissionInProgress.emit({betId: id, submitting: submitting})
    }
}
