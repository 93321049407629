<head>
  <title>Offer- Free Bets</title>
</head>
<body>

<section class="offer-modal" [id]="'offer-modal'">
  <header class="offer-modal__header" [id]="'offer-header'" *ngIf="!isSmallGlass">
    <h1><i class="icon--offer--modal"></i>Offer</h1>
    <button class="icon-button" (click)="closeModal(false)"><i class="icon--close--standard"></i></button>
  </header>
  <nav class="offer-modal__nav-bar" [id]="'offer-nav-bar'">
    <div class="offer-claimed-indicator" *ngIf="currentOffer.claimed">
      <i class="icon--check-circle-small--action"></i>
      OFFER CLAIMED
    </div>
    <span class="numeric-indicator">{{currentOfferIndex + 1}} of {{raceOfferIndicatorDetails.offers.length}}</span>
    &nbsp;&nbsp;
    <button class="icon-button" *ngIf="currentOfferIndex !== 0" (click)="selectPreviousOffer()"><i class="icon--arrow-left--gray"></i></button>
    <button class="icon-button disabled" *ngIf="currentOfferIndex === 0"><i class="icon--arrow-left--gray"></i></button>
    <button class="icon-button" *ngIf="currentOfferIndex + 1 < raceOfferIndicatorDetails.offers.length" (click)="selectNextOffer()"><i class="icon--arrow-right--gray active-arrow"></i></button>
    <button class="icon-button disabled" *ngIf="currentOfferIndex + 1 === raceOfferIndicatorDetails.offers.length"><i class="icon--arrow-right--gray"></i></button>
  </nav>
  <main>
    <figure class="offer-modal__hero-pic" [id]="'offer-image'">
      <img [src]="currentOffer.imageUrl ? currentOffer.imageUrl : defaultOfferImage | sharedResource">
    </figure>
    <h2 [id]="'offer-title'"> {{currentOffer.title}} </h2>
    <h3>DESCRIPTION</h3>
    <p [innerHTML]="currentOffer.description | cduxSanitizeHtml" [id]="'offer-description'"></p>
  </main>
  <footer>
    <nav class="offer-modal__button-wrapper">
      <button class="button-outline--secondary dark-text" [id]="'offer-details-button'" (click)="handleDetailsButton()">details</button>
      <button class="button--highlight" [id]="'offer-claim-button'"
              (click)="handleClaimNow()"
              *ngIf="!currentOffer.claimed && (buttonState$ | async) === 'claim now'"> claim now</button>

      <button class="button--highlight" *ngIf="(buttonState$ | async) === 'loading'" [id]="'offer-loading-button'">
        <div class="button-loader black">
          <div class="loader-dots ">
            <i class="dots-animated"></i>
            <i class="dots-animated"></i>
            <i class="dots-animated"></i>
            <i class="dots-animated"></i>
          </div>
        </div>
      </button>

      <button class="button--action" [id]="'offer-bet-now-button'"
              (click)="closeModal(true)"
              *ngIf="(buttonState$ | async) === 'success' || currentOffer.claimed">bet now</button>
    </nav>

    <div *ngIf="showOfferClaimErrorMessage" class="offer-modal__error-message" id="offer-modal-error-message">
      <p>You cannot claim an offer at this time. <br> Please try again later.</p>
    </div>

    <div class="offer-modal__expiration-date" [id]="'offer-modal-expiration-date'">
      Expiration Date: {{currentOffer.endTime | date: 'mediumDate'}}
    </div>
  </footer>
</section>
</body>
