import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
    enumStatus,
    IPaypalCompleteDepositStatus,
    IPaypalDepositStatus,
    IPaypalRedirectUrls,
    JwtSessionService,
    PaypalDataService,
    FeatureToggleDataService,
} from '@cdux/ng-common';

import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';

@Injectable()
export class FundingPaypalService {
    public static readonly DEEPLINK_ACTION = 'paypalCompleteDeposit';
    public static readonly PAYPAL_TRANSACTION_PARAM = 'ppTransaction';
    private static readonly _RE_PATH_BASE = /^(\/Feature\/.+)?\/bet\b/;

    public readonly BET_ID_PARAM = 'betId';
    public readonly _NO_USER_ERROR_CODE = 9998;
    public readonly _UNEXPECTED_ERROR_CODE = 9999;
    public readonly _WRONG_PAYPAL_ACCOUNT_CODE = 2131; // USER-6588: copied from paypal-complete-deposit
    public readonly _TOTE_DOWN_CODE = 1008;

    /**
     * Keep track of any transaction tokens that we have processed
     */
    private processedTokens: { [key: string]: boolean } = {};

    constructor(
        private _jwtSessionService: JwtSessionService,
        private _paypalDataService: PaypalDataService,
        private _featureToggleDataService: FeatureToggleDataService,
    ) { }

    public initiateDeposit(amount: number, currentUrl: string, paypalEmail: string, currentBetId?: string): Observable<IPaypalRedirectUrls> {

        const user = this._jwtSessionService.getUserInfo();
        if (user) {
            const redirectUrl = this._buildUrl(currentUrl, false, currentBetId);
            const cancelUrl = this._buildUrl(currentUrl, true, currentBetId);
            return this._paypalDataService.initiateDeposit(+user.account, amount, redirectUrl, cancelUrl, paypalEmail).pipe(
                catchError((err) =>  this._throwErrorCode(err) )
            );
        } else {
            const noUserError: IPaypalRedirectUrls = {
                errorCode: this._NO_USER_ERROR_CODE,
                errorMessage: 'No user information was available to initiate Deposit',
                mobileRedirectionUrl: '',
                webRedirectionUrl: ''
            };
            return of(noUserError);
        }
    }

    public completeDeposit(token: string): Observable<IPaypalCompleteDepositStatus> {
        const user = this._jwtSessionService.getUserInfo();
        if (user) {
            return this._paypalDataService.completeDeposit(+user.account, token).pipe(
                tap((res) => this.processedTokens[token] = true),
                catchError((err) => this._throwErrorCode(err))
            );
        } else {
            const noUserError = {
                depositStatus: 'failed',
                status: 'failed',
                errorCode: this._NO_USER_ERROR_CODE,
                errorMessage: 'No user information was available to complete Deposit'
            };
            return of(noUserError);
        }
    }

    public cancelDeposit(token: string): Observable<IPaypalDepositStatus> {
        const user = this._jwtSessionService.getUserInfo();

        if (user) {
            return this._paypalDataService.cancelDeposit(+user.account, token).pipe(
                tap((res) => this.processedTokens[token] = true),
                map((res: IPaypalDepositStatus) => Object.assign({}, res, {status: res.depositStatus})),
                catchError((err) => this._throwErrorCode(err))
            );
        } else {
            const noUserError = {
                depositStatus: 'failed',
                status: 'failed',
                errorCode: this._NO_USER_ERROR_CODE,
                errorMessage: 'No user information was available to cancel Deposit'
            };
            return of(noUserError);
        }
    }

    /**
     * Withdraws the Specified Amount From the Linked Paypal Account
     * @param amount - Amount to be Withdrawn
     */
    public withdraw(amount: number | null, ppemail: string): Observable<any> {
        const user = this._jwtSessionService.getUserInfo();
        if (user) {
            return this._paypalDataService.withdraw(+user.account, amount, ppemail).pipe(
                catchError((err) => this._throwErrorCode(err))
            );
        } else {
            const noUserError = {
                depositStatus: 'failed',
                status: 'failed',
                errorCode: 9999,
                errorMessage: 'No user information was available to complete Withdrawal',
                amount: 0,
                fee: 0,
                total: 0
            };
            return of(noUserError);
        }
    }

    /**
     * This will lookup if we have processed the given transaction token during
     *  this instance of TUX.
     *
     * @param token the paypal transaction token you wish to lookup.
     */
    public hasTransactionCompleted(token: string): boolean {
        return this.processedTokens.hasOwnProperty(token) && this.processedTokens[token];
    }

    private _throwErrorCode(error): Observable<any> {
        let code = this._UNEXPECTED_ERROR_CODE;
        // Errors can occur in multiple separate formats
        if (error) {
            if (error.errorCode) {
                code = error.errorCode;
            } else if (error.data && error.data.error) {
                code = error.data.error.errorCode;
            } else if (error.data && error.data.depositInfo) {
                code = error.data.depositInfo.errorCode;
            } else if (error.error) {
                code = error.error.errorCode;
            }
        }

        return throwError({ errorCode: code, status: 'failed' });
    }

    private _buildUrl(baseUrl: string, isCanceling: boolean, currentBetId?: string): string {
        const url = new URL(baseUrl);

        /**
         * Before adding our parameters, and letting PayPal add its own, purge
         * the list. Other parameters, may only confuse the interface
         * (i.e., PayPal sidebar will be open after the user closes FPD).
         */
        url.search = '';


        // Replaces the action value if one was used to get here
        url.searchParams.set('action', FundingPaypalService.DEEPLINK_ACTION);

        try {
            const isFullPage = new RegExp(FullPageFundingConstants.RE_PATH_DEPOSIT).test(url.pathname);

            if (isFullPage) {
                // keep "/bet" if it exists
                const baseStr = FundingPaypalService._RE_PATH_BASE.test(url.pathname) ? url.pathname.split('/bet')[0] + '/bet' : '';
                // go to root "/deposit" route
                // TODO: restore deposit path when FPD implements PayPal completion
                // Set return path based on full page feature toggle.
                // Note: if FULL_PG_DEPOSIT2 toggle off, we still want the return URL back to sidebar /todays-races/time
                // Prefer here to use (if...else) structure for later easier handling of removing toggle FULL_PG_DEPOSIT2 when feature rollout
                if (this._featureToggleDataService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT)) {
                    url.pathname = baseStr + '/funding/deposit/paypal';
                    url.searchParams.set('action', FundingPaypalService.DEEPLINK_ACTION + FullPageFundingConstants.SUFFIX_ACTION_FULL_PAGE);
                } else {
                    url.pathname = baseStr + '/todays-races/time';
                    url.searchParams.set('action', FundingPaypalService.DEEPLINK_ACTION);
                }
            }
        } catch (e) {
            console.error('Unable to determine PayPal deposit route.');
        }

        url.searchParams.set(FundingPaypalService.PAYPAL_TRANSACTION_PARAM, (isCanceling ? enumStatus.CANCELLED : enumStatus.SUCCESS));

        if (currentBetId !== null && currentBetId !== undefined) {
            url.searchParams.set(this.BET_ID_PARAM, currentBetId);
        }

        return url.toString();
    }
}
