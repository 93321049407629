/**
 * Extend the bet-error-codes enumerator with special messages from BetSHare.
 * The BetSlipErrorsService -> mapErrorCode will map the Bet Share error messages
 * to standard Tote Error messages.
 */
export enum BET_SHARE_ERROR_CODES {
    INSUFFICIENT_FUNDS = 'BETSHARE AMOUNT EXCEEDS CAPTAIN\'S BALANCE:',
    RACE_CLOSED = 'MINUTES OR LESS TO POST TIME. CANNOT PLACE WAGER',
    SCRATCH = 'ONE OF THESE RUNNERS IS NO LONGER AVAILABLE',
}
