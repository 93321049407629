import { Injectable } from '@angular/core';
import { CduxStorageService } from '@cdux/ng-platform/web';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {
    enumStatus, IOfferIndicatorRace,
    IOfferIndicatorResponse,
    IOfferIndicatorTrack,
    ITrackBasic,
    OffersDataService
} from '@cdux/ng-common';
import {from, Observable, of} from 'rxjs';
import {OfferIndicatorInfo} from '../class/offer-indicator-info';

@Injectable({ providedIn: 'root' })
export class OfferIndicatorSessionService {
    constructor(private _offersDataService: OffersDataService,
                private cduxStorageService: CduxStorageService) {}

    private CACHE_TTL: number = null;
    private _cacheTimestamp: number = null;

    public initializeOfferIndicatorTtl(ttlInMinutes: number): Promise<void> {
        return new Promise((resolve) => {
            this.CACHE_TTL = ttlInMinutes * 60 * 1000; // Convert minutes to milliseconds
            resolve();
        });
    }

    public getOfferIndicator(): Observable<IOfferIndicatorTrack[]> {
        return this.checkOffersListStorage().pipe(
            switchMap((offerIndicatorBonusList: IOfferIndicatorTrack[]) => {
                if (offerIndicatorBonusList && this._cacheTimestamp && Date.now() - this._cacheTimestamp < this.CACHE_TTL) {
                    return of(offerIndicatorBonusList);
                } else {
                    return this.getOfferIndicatorData();
                }
            }),
            take(1)
        );
    }

    public getOfferIndicatorData(): Observable<IOfferIndicatorTrack[]> {
        return this._offersDataService.getOffersIndicatorData().pipe(
            catchError((err) => {
                this.cduxStorageService.store(new OfferIndicatorInfo(null));
                return [];
            }),
            map((response: IOfferIndicatorResponse) => {
                if (response && response.modelStatus?.toLowerCase() === enumStatus.SUCCESS && response.bonusTrackIndicatorModels) {
                    this.cduxStorageService.store(new OfferIndicatorInfo(response.bonusTrackIndicatorModels));
                    this._cacheTimestamp = Date.now();
                    return response.bonusTrackIndicatorModels;
                } else {
                    this.cduxStorageService.store(new OfferIndicatorInfo(null));
                    return [];
                }
            }),
            take(1)
        );
    }

    public checkOffersListStorage(): Observable<IOfferIndicatorTrack[]> {
        return from(this.cduxStorageService.fetch({ db: OfferIndicatorInfo.DB, _id: OfferIndicatorInfo.KEY, version: OfferIndicatorInfo.VERSION }))
            .pipe(
                map((storedOffersList: OfferIndicatorInfo) => {
                    return storedOffersList ? storedOffersList.offerIndicatorBonusList : [];
                }),
                catchError(() => of(null))
            );
    }

    public filterRaces(bonusTrackIndicatorList: IOfferIndicatorTrack[], track: ITrackBasic): IOfferIndicatorRace {
        const trackModel = bonusTrackIndicatorList && bonusTrackIndicatorList.find(model =>
            model.track.toLowerCase() === track.BrisCode.toLowerCase() && model.trackType.toLowerCase() === track.TrackType.toLowerCase() && model.races.some((race) => race.raceNumber === track.RaceNum)
        );
        return trackModel ? trackModel.races.find((race) => race.raceNumber === track.RaceNum) : null;
    }
}
