import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { catchError, take } from "rxjs/operators";
import { BehaviorSubject, of } from "rxjs";
import {
    EventClickAttributeType,
    EventClickType,
    IOfferIndicator,
    IOfferIndicatorRace,
    JwtSessionService,
    OffersDataService,
    OfferType,
    UserEventEnum
} from '@cdux/ng-common';
import { ModalService } from "@cdux/ng-platform/web";
import { EventTrackingService } from '../../../../../../shared/event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-program-offer-claim',
    templateUrl: './program-offer-claim.component.html',
    styleUrls: ['./program-offer-claim.component.scss']
})
export class ProgramOfferClaimComponent implements OnInit, OnDestroy {

    private _offerClaimed = new EventEmitter<void>();

    public set context(context: any) {
        if (!!context) {
            this.raceOfferIndicatorDetails = context.raceOfferIndicatorDetails;
            this.isSmallGlass = context.isSmallGlass;
            this._offerClaimed = context.offerClaimed;
        }
    }

    public raceOfferIndicatorDetails: IOfferIndicatorRace;
    public currentOfferIndex: number;
    public isSmallGlass = false;
    public currentOffer: IOfferIndicator;
    public buttonStateSubject = new BehaviorSubject<'claim now' | 'loading' | 'success'>('loading');
    public showOfferClaimErrorMessage: boolean;
    buttonState$ = this.buttonStateSubject.asObservable();
    public defaultOfferImage = 'resources/shared/images/default_offer_image_modal.png';

    constructor(
        private _modalService: ModalService,
        private _jwtSessionService: JwtSessionService,
        private _router: Router,
        private _offersDataService: OffersDataService,
        private _eventTrackingService: EventTrackingService
    ) { }

    ngOnInit() {
        this.currentOfferIndex = 0;
        this.currentOffer = this.raceOfferIndicatorDetails?.offers[this.currentOfferIndex];
        this._updateFromSubject();
    }

    ngOnDestroy() {
    }

    public closeModal(isFromBetNow: boolean): void {
        if (isFromBetNow) {
            this._eventTrackingService.logClickEvent(EventClickType.BET_NOW_WAGER_PROGRAM);
            this._eventTrackingService.logUserEvent(UserEventEnum.PROGRAM_OFFER_BET_NOW);
        }
        this._modalService.closeAll();
    }

    private _updateFromSubject(): void {
        if (!this.currentOffer.claimed) {
            this.buttonStateSubject.next('claim now');
        } else {
            this.buttonStateSubject.next('success');
        }
    }

    public selectNextOffer(): void {
        this.showOfferClaimErrorMessage = false;
        this.currentOfferIndex = this.currentOfferIndex + 1;
        this.currentOffer = this.raceOfferIndicatorDetails.offers[this.currentOfferIndex];
        this._updateFromSubject();
    }

    public selectPreviousOffer(): void {
        this.showOfferClaimErrorMessage = false;
        this.currentOfferIndex = this.currentOfferIndex - 1;
        this.currentOffer = this.raceOfferIndicatorDetails.offers[this.currentOfferIndex];
        this._updateFromSubject();
    }

    private _goToLogin(): void {
        this._jwtSessionService.redirectLoggedInUserUrl = this._router.url;
        this._jwtSessionService.redirectInputs = this._router.parseUrl(this._router.url).queryParams;
        this._router.navigate(['/login']);
        this.closeModal(false);
    }

    public handleClaimNow(): void {
        this._eventTrackingService.logClickEvent(EventClickType.CLAIM_NOW_WAGER_PROGRAM, [
            { attrId: EventClickAttributeType.CLAIM_NOW_WAGER_PROGRAM_OFFER_UUID, data: this.currentOffer.uuid },
            { attrId: EventClickAttributeType.CLAIM_NOW_WAGER_PROGRAM_OFFER_NAME, data: this.currentOffer.title }
        ]);
        this._eventTrackingService.logUserEvent(UserEventEnum.PROGRAM_OFFER_CLAIM, {
           uuid: this.currentOffer.uuid,
           offerName: this.currentOffer.title
        });
        if (this._jwtSessionService.isLoggedIn()) {
            this.buttonStateSubject.next('loading');
            this._claimOffer();
        } else {
            this._goToLogin();
        }
    }

    private _claimOffer(): void {
        this._offersDataService.assign(this.currentOffer.redemptionCode, OfferType.BONUS).pipe(
            take(1),
            catchError((err) => {
                this.buttonStateSubject.next('claim now');
                console.error('Error claiming offer:', err);
                this.showOfferClaimErrorMessage = true;
                return of(null);
            })
        ).subscribe((response) => {
            if (response?.OptIn?.status?.message === "SUCCESS") {
                this.showOfferClaimErrorMessage = false;
                this.buttonStateSubject.next('success');
                this.currentOffer.claimed = true;
                this._offerClaimed.emit();
            } else {
                this.showOfferClaimErrorMessage = true;
                this.buttonStateSubject.next('claim now');
            }
        });
    }

    public handleDetailsButton(): void {
        this._eventTrackingService.logClickEvent(EventClickType.OFFER_DETAILS_WAGER_PROGRAM);
        this._eventTrackingService.logUserEvent(UserEventEnum.PROGRAM_OFFER_LEARN);
        this._redirectToOfferDetails();
        this.closeModal(false);
    }

    private _redirectToOfferDetails(): void {
        this._router.navigate(['offers',this.currentOffer.redemptionCode,'details']);
    }
}
