/**
 * Provides a (more) developer-friendly key for the strings returned from
 * the wagering systems. Those strings are then translated using the
 * LANG_WAGER_ERRORS constant defined in src/languages/wager/errors.ts.
 */
export enum BET_ERROR_CODES {
    BETS_FAILED = 'MULTIPLE BETS FAILED',
    BS_SSN = 'Invalid SSN. Sorry, we were unable to verify your Social Security Number. Please resubmit your Bet Share, and try again.',
    INSUFFICIENT_FUNDS = 'ACCOUNT BALANCE IS LESS THAN COST OF TRANSACTION',
    INSUFFICIENT_FUNDS_QUICKBET = 'QUICKBET ACCOUNT BALANCE IS LESS THAN COST OF TRANSACTION',
    INSUFFICIENT_FUNDS_NODEPOSIT = 'ACCOUNT BALANCE IS LESS THAN COST OF TRANSACTION BUT NO DEPOSIT REDIRECT',
    GEOLOCATION_REQUIRED = 'GEOLOCATION REQUIRED',
    MAX_EXCEEDED = 'REQUESTED BET AMOUNT IS NOT ALLOWED',
    MIN_NOT_MET = 'BET AMOUNT IS TOO SMALL',
    POOL_CLOSED = 'REQUESTED POOL IS NOT DEFINED IN THE SYSTEM',
    PROGRAM_NOT_AVAILABLE = 'REQUESTED PROGRAM IS NOT AVAILABLE',
    RACE_CLOSED = 'RACE IS CLOSED - NO FURTHER BETS ARE ALLOWED',
    RESTRICT_PHONE_ONLY = 'Online wagering is restricted for your state.',
    RESTRICT_TRACK = 'Wagering is restricted for your state.',
    SCRATCH = 'Sorry! One of these runners is no longer available. Please modify your picks & resubmit your wager.',
    BETSHARE_MTP_RESTRICTION = 'Selected Race is %s minutes or less to Post Time. Cannot place wager.',
    SSN_REQUIRED = 'You must provide your full Social Security Number to place a BetShare wager.',
    CONDITIONAL_RESTRICTED = 'Conditional wagering is restricted at this track.',
    CONDITIONAL_UNAVAILABLE = 'Due to technical issues we cannot accept conditional wagers at this time.  Please try again later.',
    EXPIRED_JWT = 'Expired JWT',
    UNKNOWN_ERROR = 'fallback',
    MAXIMUM_SHARES = 'MAXIMUM SPLIT SHARE VALUE IS 1000.',
    MINIMUM_SHARES_VALUE = 'The minimum value for an individual share is $0.01. Please enter a valid amount',
    INVALID_RESERVE_AMOUNT = 'Please enter a valid amount',
    INVALID_RESERVE_MIN = 'Minimum reserve share value is 1.',
    INVALID_RESERVE_MAX = 'Maximum shares reserved must be less than the total shares available.'
}
