import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { EventClickType, IOfferIndicatorRace, UserEventEnum } from '@cdux/ng-common';
import { ProgramOfferClaimComponent } from '../program-offer-claim/program-offer-claim.component';
import { CduxMediaToggleService, DropupModalConfig, DropupPrebuiltComponent, ModalRef, ModalService } from '@cdux/ng-platform/web';
import { take, takeUntil } from 'rxjs/operators';
import { OfferIndicatorRaceRequestHandler } from '../../class/offer-indicator-race-request-handler';
import { EventTrackingService } from '../../../../../../shared/event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-offer-indicator-icon',
    templateUrl: './offer-indicator-icon.component.html',
    styleUrls: ['./offer-indicator-icon.component.scss']
})
export class OfferIndicatorIconComponent implements OnInit, OnDestroy {

    @Input() public raceOfferIndicatorDetails: IOfferIndicatorRace;
    @Input() public offerIndicatorRaceRequestHandler: OfferIndicatorRaceRequestHandler;
    private _isOfferClaimed: boolean;
    private _modalRef: ModalRef<any>;
    private _offerClaimed = new EventEmitter<void>();

    public isSmallGlass = false;

    constructor(
        private _mediaToggle: CduxMediaToggleService,
        private _modalService: ModalService,
        private _eventTrackingService: EventTrackingService
    ) { }

    ngOnInit() {
        this.isSmallGlass = this._mediaToggle.query('phone');
    }

    ngOnDestroy() { }

    public showOffersClaimModal(): void {
        if (!this._modalRef) {
            this._eventTrackingService.logClickEvent(EventClickType.OFFERS_WAGER_PROGRAM);
            this._eventTrackingService.logUserEvent(UserEventEnum.PROGRAM_OFFER);
            const modalConfig = this._getModalConfig();

            this._modalRef = this.isSmallGlass ? this._openDropup() : this._modalService.open(ProgramOfferClaimComponent, modalConfig);

            this._subscribeToOfferClaimed();
            this._subscribeToModalClose();
        }
    }

    private _getModalConfig() {
        return {
            padding: '0px',
            maxHeight: '100vh',
            maxWidth: '100vh',
            overflow: 'hidden',
            hasBackdrop: true,
            context: {
                raceOfferIndicatorDetails: this.raceOfferIndicatorDetails,
                isSmallGlass: this.isSmallGlass,
                offerClaimed: this._offerClaimed
            }
        };
    }

    private _subscribeToOfferClaimed() {
        this._offerClaimed.pipe(take(1)).subscribe(() => {
            this._isOfferClaimed = true;
        });
    }

    private _subscribeToModalClose() {
        this._modalRef.afterClosed.pipe(take(1)).subscribe(() => {
            if (this._isOfferClaimed) {
                this.offerIndicatorRaceRequestHandler.clearCache();
            }
            this._isOfferClaimed = false;
            this._modalRef = null;
        });
    }

    private _openDropup(): ModalRef<any> {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.context = {
            headerText: 'OFFER',
            component: ProgramOfferClaimComponent,
            attachedCallback: (ref) => {
                ref.instance.context = {
                    raceOfferIndicatorDetails: this.raceOfferIndicatorDetails,
                    isSmallGlass: this.isSmallGlass,
                    offerClaimed: this._offerClaimed
                }
            }
        };

        const modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        modalRef.componentInstance.close.pipe(takeUntil(modalRef.afterClosed)).subscribe(() => modalRef.close());

        return modalRef;
    }
}
