import { CduxStorage, IOfferIndicatorTrack } from '@cdux/ng-common';


export class OfferIndicatorInfo  implements CduxStorage.DynamicStoreable {
    public static readonly DB = 'offerIndicatorInfo';
    public static readonly VERSION = 1;
    public static readonly KEY = 'offerIndicatorInfo';

    public offerIndicatorBonusList: IOfferIndicatorTrack[];

    constructor(bonusList: IOfferIndicatorTrack[]) {
        this.offerIndicatorBonusList = bonusList;
    }

    /**
     * Tells the data store how to store this model.
     *
     * @returns {CduxStorage.StorageQuery}
     */
    public generateStoreableMetadata(): CduxStorage.StorageQuery {
        return {
            db: OfferIndicatorInfo.DB,
            _id: OfferIndicatorInfo.KEY,
            data: this,
            version: OfferIndicatorInfo.VERSION
        };
    }
}
