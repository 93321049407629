<cdux-dropdown class="bet-dropdown-selector amount bet-amount-dropdown"
               name="bet-amount"
               [(ngModel)]="value"
               [forceState]="onSmallGlass ? smallGlassState : 0">

    <div dropdown-header *ngIf="!suppressHeader">
        <div class="menu-tab">
            <h2 class="title-label">
                    <span id="bet-nav-selected-amount" *ngIf="value">
                        {{ value.value | formatBetAmount }}
                    </span>
            </h2>
        </div>
        <div class="dropdown-arrow">
            <i class="icon--arrow-down"></i>
        </div>
    </div>

    <div dropdown-list>
        <cdux-dropdown-option *ngFor="let amount of list; trackBy:trackByIndex; let $index = index;"
                              [value]="amount">
                <span class="option-label" id="bet-nav-amount-option-{{$index + 1}}">
                    {{ amount.value | formatBetAmount }}
                </span>
        </cdux-dropdown-option>
        <!-- Added the option to allow users to add custom bet amount -->
        <cdux-dropdown-option *ngIf="list.length > 1 && hideOtherAmount !== true" [value]="value" (click)="openBetCustomAmountModal()" [cduxLogClick]="eventClickType.BET_CUSTOM_AMOUNT_OTHER">
            <span class="option-label" id="bet-nav-amount-option-other">other</span>
        </cdux-dropdown-option>
    </div>

</cdux-dropdown>
