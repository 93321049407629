import { Injectable } from '@angular/core';
import { IBetShareDetails, IGetDataResponse, IGetMyBetsResponse, WagerService } from '@cdux/ng-common';
import { IWager, PayoutCalculationService, WagerDisplayStatus, WagerDisplayStatusService, WagerStatus, BetShareUtilService } from '@cdux/ng-fragments';
import { BetsCommonService } from './bets-common.service';

@Injectable({providedIn: 'root'})
export class WagerFactoryService {

    constructor(private payoutCalculatorService: PayoutCalculationService,
                private wagerDisplayService: WagerDisplayStatusService,
                private _betShareUtil: BetShareUtilService,
                private betsCommonService: BetsCommonService) {}

    public createActiveWager(bet: IGetDataResponse, betShareDetails?: IBetShareDetails): IWager {
        return {
            delimiter: ',',
            // certain tracks(at least in test environments) do not return a track name
            trackName: bet.trackName || bet.eventCode,
            activityDate: new Date(bet.datetimeUTC),
            // event date received as yyyy-mm-dd
            eventDate: !!bet.eventDate ? this.createEventDate(bet.eventDate) : null,
            wagerAmount: +bet.totalCost,
            wagerType: bet.betTypeDisplayName,
            wagerTypeId: bet.wagerTypeId,
            tournamentName: bet.tournamentName,
            quickPick: bet.quickPick,
            runners: bet.runnersList,
            raceNum: bet.race,
            status: this.updateMyBetsStatus(bet.status, bet.frozenWager),
            amount: +bet.baseAmount,
            serialNumber: bet.serialNumber,
            isExpanded: false,
            brisCode: bet.brisCode,
            trackType: bet.trackType,
            raceStatus: '',
            eventCode: bet.eventCode,
            isDeleted: false,
            poolType: WagerService.normalizeBetCode(bet.poolType),
            payoutRaceDate: bet.payoutRaceDate,
            payoutTrackBrisCode: bet.payoutTrackBrisCode,
            payoutTrackAtabCode: bet.payoutTrackAtabCode,
            payoutRaceNumber: bet.payoutRaceNumber,
            betShareData: {
                betShare: bet.betShare === 'true',
                betShareCaptain: bet.betShareCaptain === 'true',
                betShareCost: bet.betShareCost,
                betShareId: bet.betShareId,
                betShareInfo: betShareDetails,
                betShareNumberOfShares: bet.betShareNumberOfShares,
                betShareStatusId: bet.betShareStatusId,
                participantCost: bet.participantCost,
                participantShares: bet.participantShares,
                pricePerShare: bet.pricePerShare,
                availableShares: bet.availableShares,
                availableAmount: bet.availableAmount
            },
            condWagerData: {
                conditionalWager: bet.conditionalWager === 'true',
                conditionalMaxMtp: bet.conditionalMaxMtp,
                conditionalMaxOdds: bet.conditionalMaxOdds,
                conditionalOdds: bet.conditionalOdds,
                conditionalMaxProbablePayoff: bet.conditionalMaxProbablePayoff,
                conditionalProbablePayout: bet.conditionalProbablePayoff
            },
            parlay: bet.parlay ? bet.parlay : false,
            parlayBets: bet.parlayBets
        }
    }

    public createTodaysCompletedWager(bet: IGetDataResponse): IWager {
        const wagerType  = this.betsCommonService.getPoolType(bet.poolType);
        let wagerTotal, status;
        if (bet.betShare) {
            const wagerStatus = this._betShareUtil.isBetShareCancelled(bet.betShareStatusId) ?  bet.betShareStatusId : bet.status;
            wagerTotal = this.payoutCalculatorService.calculateTotal(+bet.totalCost, +bet.payoutAmount, +bet.refundAmount, wagerStatus);
            status = this.wagerDisplayService.formatDisplayWagerStatus(wagerStatus,  +bet.totalCost, +bet.payoutAmount, +bet.refundAmount);
        } else {
            wagerTotal = this.payoutCalculatorService.calculateTotal(+bet.totalCost, +bet.payoutAmount, +bet.refundAmount, bet.status, bet.failReason);
            status = this.wagerDisplayService.formatDisplayWagerStatus(bet.status,  +bet.totalCost, +bet.payoutAmount, +bet.refundAmount, bet.failReason);
        }

        return {
            activityDate: new Date(bet.datetimeUTC),
            amount: +bet.baseAmount,
            brisCode: bet.brisCode,
            delimiter: ',',
            eventCode: bet.eventCode,
            eventDate: !!bet.eventDate ? this.createEventDate(bet.eventDate) : null,
            finishOrder: null,
            isExpanded: false,
            isFrozen: bet.frozenWager === 'true',
            raceNum: bet.race,
            runners: bet.runnersList,
            serialNumber: bet.serialNumber,
            status: status,
            trackName: bet.trackName,
            trackType: bet.trackType,
            wagerAmount: +bet.totalCost,
            wagerTotal: wagerTotal,
            wagerTypeId: bet.wagerTypeId,
            tournamentName: bet.tournamentName,
            wagerType: wagerType || bet.betTypeDisplayName,
            quickPick: bet.quickPick,
            poolType: WagerService.normalizeBetCode(bet.poolType),
            payoutRaceDate: bet.payoutRaceDate,
            payoutTrackBrisCode: bet.payoutTrackBrisCode,
            payoutTrackAtabCode: bet.payoutTrackAtabCode,
            payoutRaceNumber: bet.payoutRaceNumber,
            betShareData: {
                betShare: bet.betShare === 'true',
                betShareCaptain: bet.betShareCaptain === 'true',
                betShareId: bet.betShareId,
                participantCost: bet.participantCost,
                pricePerShare: bet.pricePerShare,
                participantShares: bet.participantShares,
                betShareCost: bet.betShareCost,
                betShareNumberOfShares: bet.betShareNumberOfShares,
                betShareStatusId: bet.betShareStatusId
            },
            condWagerData: {
                conditionalWager: bet.conditionalWager === 'true',
                conditionalMaxMtp: bet.conditionalMaxMtp,
                conditionalMaxOdds: bet.conditionalMaxOdds,
                conditionalOdds: bet.conditionalOdds,
                conditionalMaxProbablePayoff: bet.conditionalMaxProbablePayoff,
                conditionalProbablePayout: bet.conditionalProbablePayoff,
                conditionalFailureReason: bet.failReason
            },
            parlay: bet.parlay ? bet.parlay : false,
            parlayBets: bet.parlayBets
        };
    }

    public createPastCompletedWager(bet: IGetMyBetsResponse): IWager {
        // find out if bet is bet share wager and it was cancelled
        let wagerTotal, status;
        if (bet.conditionalWager) {
            wagerTotal = this.payoutCalculatorService.calculateTotal(+bet.DebitAmt, +bet.CreditAmt, +bet.RefundAmt, bet.conditionalStatus, bet.conditionalFailedReason);
            status = this.wagerDisplayService.formatDisplayWagerStatus(bet.conditionalStatus, +bet.DebitAmt, +bet.CreditAmt, +bet.RefundAmt, bet.conditionalFailedReason);
        } else {
            wagerTotal = this.payoutCalculatorService.calculateTotal(+bet.DebitAmt, +bet.CreditAmt, +bet.RefundAmt, bet.TxType);
            status = this.wagerDisplayService.formatDisplayWagerStatus((bet.betShare && this._betShareUtil.isBetShareCancelled(bet.betShareStatusId)) ? bet.betShareStatusId : bet.TxType, +bet.DebitAmt, +bet.CreditAmt, +bet.RefundAmt);
        }

        return  {
            activityDate: new Date(bet.ActivityDateTimeUTC),
            amount: +bet.Amount,
            brisCode: bet.brisCode,
            delimiter: ' ',
            eventCode: bet.TrackCode,
            eventDate: !!bet.EventDate ? this.createEventDate(bet.EventDate) : null,
            finishOrder: null,
            isExpanded: false,
            isFrozen: bet.isFrozen,
            raceNum: bet.Race.toString(),
            runners: bet.Runners,
            serialNumber: bet.SerialNum,
            status: status,
            trackName: bet.TrackName,
            trackType: bet.trackType,
            wagerAmount: +bet.Amount,
            wagerTotal:  wagerTotal,
            wagerType: bet.PoolName,
            wagerTypeId: bet.wagerTypeId,
            tournamentName: bet.tournamentName,
            quickPick: bet.quickPick,
            poolType: WagerService.normalizeBetCode(bet.PoolType),
            betShareData: {
                betShare: bet.betShare ? bet.betShare : false,
                betShareCaptain: bet.betShareCaptain ? bet.betShareCaptain : false,
                betShareId: bet.betShareId,
                participantCost: bet.participantCost,
                pricePerShare: bet.pricePerShare,
                participantShares: bet.participantShares,
                betShareCost: bet.betShareCost,
                betShareNumberOfShares: bet.betShareNumberOfShares,
                betShareStatusId: bet.betShareStatusId
            },
            condWagerData: {
                conditionalWager: bet.conditionalWager,
                conditionalFailureReason: bet.conditionalFailedReason,
                conditionalMaxMtp: bet.conditionalMaxMtp,
                conditionalMaxOdds: bet.conditionalMaxOdds,
                conditionalOdds: bet.conditionalOdds,
                conditionalMaxProbablePayoff: bet.conditionalMaxProbablePayoff,
                conditionalProbablePayout: bet.conditionalProablePayoff
            },
            parlay: bet.parlay ? bet.parlay : false,
            parlayBets: bet.parlayBets
        }
    }

    /**
     * @param {string} status
     * @param {string} frozenWager
     * @returns {string} wager status for MyBets displayed to user
     */
    public updateMyBetsStatus(status: string, frozenWager: string): string | null {
        if (frozenWager === 'true')  {
            return WagerDisplayStatus.PENDING;
        } else {
            const enumStatus = !!status ? status.toLowerCase() : '';
            switch (enumStatus) {
                case WagerStatus.OPEN.toLowerCase():
                case WagerStatus.ACTIVE.toLowerCase():
                    return WagerDisplayStatus.OPEN;
                case WagerStatus.PLACED.toLowerCase():
                case WagerStatus.COMPLETED.toLowerCase():
                    return WagerDisplayStatus.ACCEPTED;  // Return ACCEPTED for both PLACED and COMPLETED
                case WagerStatus.CANCELED.toLowerCase():
                case WagerStatus.CANCELLED.toLowerCase():
                    return WagerDisplayStatus.CANCELLED;
                default:
                    return status || null;  // Return null if status is falsy
            }
        }
    }

    private createEventDate(date: string): Date {
        const dateParts = date.split('-');
        // months are 0-based
        return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]);
    }
}
