import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { IOfferIndicatorTrack } from '@cdux/ng-common';
import { CduxStorageService } from '@cdux/ng-platform/web';
import { Observable, ReplaySubject, of, merge, from } from 'rxjs';
import { catchError, debounceTime, publishReplay, refCount, switchMap, take, takeUntil } from 'rxjs/operators';
import { OfferIndicatorSessionService } from '../services/offer-indicator-session-service';
import { OfferIndicatorInfo } from './offer-indicator-info';

export class OfferIndicatorRaceRequestHandler extends CduxRxJSBuildingBlock<any, IOfferIndicatorTrack[]> {

    protected _stream: Observable<IOfferIndicatorTrack[]>;
    private _emptyCache: ReplaySubject<void> = new ReplaySubject<void>(1);

    constructor(private _offersDataService: OfferIndicatorSessionService,
                private _cduxStorageService: CduxStorageService) {
        super();
        this._init();
    }

    public kill() {
        super.kill();
        this._emptyCache.complete();
    }

    public clearCache() {
        this._emptyCache.next();
    }

    public _init() {
        const emptyCacheObs = this._emptyCache.pipe(
            switchMap(() =>
                from(this._cduxStorageService.store(new OfferIndicatorInfo(null)).then(() => {
                    return this._getOfferIndicator().toPromise();
                }))
            )
        );

        this._stream = merge(emptyCacheObs, this._getOfferIndicator()).pipe(
            debounceTime(0),
            takeUntil(this._kill),
            publishReplay(1),
            refCount()
        ) as Observable<IOfferIndicatorTrack[]>;
    }

    private _getOfferIndicator(): Observable<IOfferIndicatorTrack[]> {
        return this._offersDataService.getOfferIndicator().pipe(
            catchError((err) => of([])),
            take(1)
        );
    }

}
