<div class="bet-slip" [class.is-shrunk]="isShrunk" [class.is-batch-submitted]="externallySubmitted" [class.display]="externallySubmitted">

    <div class="bet-success-animation-container" [class.is-showing]="submitSuccess || forceSubmitAnimation || showBetShareSuccess">
        <!-- begin inserted icon-alert-->
        <ng-container *ngIf="!(bet.betShare && bet.isSubmittedWager); else betShareSuccess" >
            <div class="icon-bet-success-wrapper">
                <svg class="icon-bet-success" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                    <circle class="icon-bet-success-circle" cx="26" cy="26" r="26"></circle>
                    <path class="icon-bet-success-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                </svg>
            </div>
        </ng-container>
        <ng-template #betShareSuccess>
            <cdux-bet-share-success [bet]="bet" (closeShareBet)="closeShareBetSuccessPanel()"></cdux-bet-share-success>
        </ng-template>
    </div>

    <cdux-ssn-collection-bet *ngIf="showSSNInput || forceSSNInput"></cdux-ssn-collection-bet>

    <div class="bet-slip__row error-row" *ngIf="betError && !externallySubmitted">
        <div class="bet-error-message">
            {{ betError.errorString }}
            <a *ngIf="betError.errorCode === INSUFFICIENT_FUNDS"
                (click)="openDepositFlow()">{{ depositButtonText }}</a>
        </div>
    </div>

    <div class="bet-slip__title-bar">
        <h2 class="title">
            <div class="track-name">{{bet.track.DisplayName}}</div>
            <div class="race-number">Race {{bet.race.race}}</div>
        </h2>
        <span class="bet-slip__bet-type">{{betTypeName}}<span [innerHTML]="betSubtypeName"></span></span>
    </div>
    <div class="bet-slip__row">
        <span class="runner-bar">
            <ng-container *ngIf="showSaddleCloths; else NoSaddleCloths">
                <cdux-runner-list class="bets" [bet]="bet" [collapseBoxedRunners]="false"></cdux-runner-list>
            </ng-container>
            <ng-template #NoSaddleCloths>
                <span class="bets">{{ runnerListConfig | runnerList }}</span>
            </ng-template>
        </span>
    </div>
        <div *ngIf="!bet.betShare || !newBetShareBetSlipView" class="bet-slip__row has-controls">
            <div class="bet-amount-controls">
                <i id="betshare_amount_decrement" class="btn-decrement" [cduxLogClick]="eventClickType.EDIT_BET_AMOUNT"
                    (click)="decreaseAmount()"></i>
                <div id="betshare_amount_value" class="bet-amount">{{ bet.amount.value | formatBetAmount }}</div>
                <i id="betshare_amount_increment" class="btn-increment" [cduxLogClick]="eventClickType.EDIT_BET_AMOUNT"
                    (click)="increaseAmount()"></i>
            </div>
        </div>
    <!--submitSuccess and externallySubmitted forces close during submit all process-->
    <!-- This is the component for when a user submits a betshare, but its too close to  -->
    <!--this is where the component begins -->
    <div class="bet-share-closed-container" *ngIf="betError?.errorCode === MTP_RESTRICTION && !externallySubmitted">
        <div class="bet-share-closed-header">Bet Share Closed</div>
        <p>We apologize but your Bet Share cannot be created at this time. There is not enough time for friends to
            join.</p>
        <p>However, your bet can still be placed.</p>
        <div class="bet-share-closed-buttons">
            <div class="btn-delete" (click)="deleteBet()" [cduxLogClick]="eventClickType.BETSHARE_MTP_RESTRICTION_DELETE">Delete</div>
            <div class="btn-submit" (click)="submitBet(true)" [cduxLogClick]="eventClickType.BETSHARE_MTP_RESTRICTION_SUBMIT">Submit</div>
        </div>
        <!--this is where the component ends -->
    </div>

    <!-- Bet Share-->
    <ng-container *ngIf="!newBetShareBetSlipView && betError?.errorCode !== MTP_RESTRICTION && bet.betShare && !bet.isSubmittedWager && !(submitSuccess || externallySubmitted)">
        <div class="bet-slip__row">
            <span class="title"><span class="split">Split</span></span>
            <cdux-tooltip class="betshare-tooltip split" [activatedOn]="'click hover'" [position]="'top'" tooltipTitle="SPLIT" >
                <span tooltip-toggle>
                    <i class="icon--info" [cduxLogClick]="eventClickType.TOOLTIP_BET_SHARE_SPLIT"></i>
                </span>
                <span tooltip-content>
                    <p> {{ 'bet-share-split' | cduxTranslate:'bet-share-tooltips' }} </p>
                </span>
            </cdux-tooltip>
            <div class="bet-amount-controls">
                <i id="betshare_shares_decrement" class="btn-decrement" (click)="updateTotalShares(false)"
                    [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_SPLIT_DECREASE"></i>
                <div id="betshare_shares_value" class="bet-split">{{bet.shares.totalShares}}</div>
                <i id="betshare_shares_increment" class="btn-increment" (click)="updateTotalShares(true)"
                    [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_SPLIT_INCREASE"></i>
            </div>
        </div>
        <div class="bet-slip__row">
            <span class="title"><span class="reserve">Reserve</span></span>
            <cdux-tooltip class="betshare-tooltip reserve" [activatedOn]="'click hover'" [position]="'top'" tooltipTitle="RESERVE">
                <span tooltip-toggle>
                    <i class="icon--info" [cduxLogClick]="eventClickType.TOOLTIP_BET_SHARE_RESERVE"></i>
                </span>
                <span tooltip-content>
                    <p> {{ 'bet-share-reserve' | cduxTranslate:'bet-share-tooltips' }} </p>
                </span>
            </cdux-tooltip>
            <div class="bet-amount-controls">
                <i id="betshare_reserve_decrement" class="btn-decrement" (click)="updateReserveShares(false)"
                    [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_RESERVE_DECREASE"></i>
                <div id="betshare_reserve_value" class="bet-reserve">{{bet.shares.reserveShares}}</div>
                <i id="betshare_reserve_increment" class="btn-increment" (click)="updateReserveShares(true)"
                    [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_RESERVE_INCREASE"></i>
            </div>
        </div>
        <div class="bet-slip__avail-shares-row">
            <span class="total">Available Shares:</span><span id="betshare_shares_total" class="amount">{{
                betShareTotals.availableValue }} </span>
        </div>
        <div class="bet-slip__reserve-row">
            <span class="total">Reserve:</span><span id="betshare_reserve_total" class="amount">{{
                betShareTotals.reservedValue}} </span>
        </div>
    </ng-container>

    <!-- NEW BETSHARE BETSLIP VIEW -->
    <ng-container *ngIf="newBetShareBetSlipView && betError?.errorCode !== MTP_RESTRICTION && bet.betShare && !bet.isSubmittedWager && !(submitSuccess || externallySubmitted)">
        <div class="bet-slip__row">
            <span class="title">Base Bet</span>
            <div class="bet-amount-controls">
                <cdux-bet-nav-amount 
                    [(ngModel)]="bet.amount" 
                    (ngModelChange)="onUpdateBetAmount($event)"
                    [hideOtherAmount]="true"
                    [list]="bet.allowedAmounts"></cdux-bet-nav-amount>
            </div>
        </div>
        <div class="bet-slip__row">
            <span class="title"><span class="split">Shares Split</span></span>
            <cdux-tooltip class="betshare-tooltip split" [activatedOn]="'click hover'" [position]="'top'" tooltipTitle="SPLIT" >
                <span tooltip-toggle>
                    <i class="icon--info" [cduxLogClick]="eventClickType.TOOLTIP_BET_SHARE_SPLIT"></i>
                </span>
                <span tooltip-content>
                    <p> {{ 'bet-share-split' | cduxTranslate:'bet-share-tooltips' }} </p>
                </span>
            </cdux-tooltip>
            <div class="shares-input-container">
                <input 
                    #sharesInput
                    id="betshare_shares_split"
                    type="text" 
                    [value]="bet.shares.totalShares"
                    (keyup)="updateTotalSharesFromInput(sharesInput.value, $event)"
                    (change)="updateTotalSharesFromInput(sharesInput.value, $event)"
                    (click)="$event.target.select()"
                    pattern="\d*"
                    inputmode="numeric"
                    maxlength="4"
                    class="shares-input">
            </div>
            <div class="max-shares-error" *ngIf="betError?.errorCode === MAXIMUM_SHARES && !externallySubmitted">{{ betError.errorCode }}</div>
        </div>
        <div class="bet-slip__row">
            <span class="title"><span class="reserve">Shares Reserved</span></span>
            <cdux-tooltip class="betshare-tooltip reserve" [activatedOn]="'click hover'" [position]="'top'" tooltipTitle="RESERVE">
                <span tooltip-toggle>
                    <i class="icon--info" [cduxLogClick]="eventClickType.TOOLTIP_BET_SHARE_RESERVE"></i>
                </span>
                <span tooltip-content>
                    <p> {{ 'bet-share-reserve' | cduxTranslate:'bet-share-tooltips' }} </p>
                </span>
            </cdux-tooltip>
            <div class="reserve-input-container">
                <input 
                    #reserveSharesInput
                    id="betshare_reserve_value"
                    type="text" 
                    [value]="bet.shares.reserveShares"
                    (keyup)="updateReserveSharesFromInput(reserveSharesInput.value, $event)"
                    (change)="updateReserveSharesFromInput(reserveSharesInput.value, $event)"
                    (click)="$event.target.select()"
                    pattern="\d*"
                    inputmode="numeric"
                    maxlength="4"
                    class="reserve-input">
            </div>
            <div class="max-shares-error" *ngIf="betError && !externallySubmitted && betError?.errorCode !== MAXIMUM_SHARES">{{ betError.errorCode }}</div>
        </div>
        <div class="bet-slip__avail-shares-row">
            <span class="total">Available Shares:</span><span id="betshare_shares_total" class="amount">{{
                betShareTotals.availableValue }} </span>
        </div>
        <div class="bet-slip__reserve-row">
            <span class="total">Reserve:</span><span id="betshare_reserve_total" class="amount">{{
                betShareTotals.reservedValue}} </span>
        </div>
    </ng-container>




    <!-- Conditional Bet -->
    <ng-container *ngIf="bet.conditional">
        <ng-container  *ngIf="showOdds; else exoticTemplate">
            <div class="bet-slip__row">
                <span class="title"> {{'conditional-min-odds' | cduxTranslate: 'conditional-wager'}} </span>
                <div class="bet-amount-controls">
                    <i class="btn-decrement" id="{{'oddsCondDecButton_' + index}}" (click)="updateConditionalWager(false, WAGER_CONDITIONS.ODDS)" [cduxLogClick]="eventClickType.CONDITIONAL_ODDS_DECREASE"></i>
                    <div class="bet-split">{{bet.conditionalOdds | odds}}</div>
                    <i class="btn-increment" id="{{'oddsIncDecButton_' + index}}" (click)="updateConditionalWager(true, WAGER_CONDITIONS.ODDS)" [cduxLogClick]="eventClickType.CONDITIONAL_ODDS_INCREASE"></i>
                </div>
            </div>
        </ng-container>
        <div class="bet-slip__row">
            <span class="title">At MTP</span>
            <div class="bet-amount-controls">
                <i class="btn-decrement" id="{{'mtpCondDecButton_' + index}}" (click)="updateConditionalWager(false, WAGER_CONDITIONS.MTP)" [cduxLogClick]="eventClickType.CONDITIONAL_MTP_DECREASE"></i>
                <div class="bet-split">{{bet.conditionalMtp}}</div>
                <i class="btn-increment" id="{{'mtpCondIncButton_' + index}}" (click)="updateConditionalWager(true, WAGER_CONDITIONS.MTP)" [cduxLogClick]="eventClickType.CONDITIONAL_MTP_INCREASE"></i>
            </div>
        </div>
        <ng-template #exoticTemplate>
            <div class="bet-slip__row">
                <span class="title">$2 Min Probable<br/>Payout</span>
                <div class="bet-amount-controls">
                    <i class="btn-decrement" id="{{'payoutCondDecButton_' + index}}" (click)="updateConditionalWager(false, WAGER_CONDITIONS.PAYOUT)" [cduxLogClick]="eventClickType.CONDITIONAL_PAYOFF_DECREASE"></i>
                    <div class="bet-split">${{bet.conditionalProbablePayout}}</div>
                    <i class="btn-increment" id="{{'payoutCondIncButton_' + index}}" (click)="updateConditionalWager(true, WAGER_CONDITIONS.PAYOUT)" [cduxLogClick]="eventClickType.CONDITIONAL_PAYOFF_INCREASE"></i>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <div class="bet-slip__total-row">
        <span class="total">{{ bet.conditional ? 'Conditional Bet:' : 'Bet Total:'}}</span>
        <span class="amount"><span id="betshare_total_total" *ngIf="bet.betShare">{{ betShareTotals.totalShareValue }}
            </span>{{total | formatBetAmount}}</span>
        <cdux-tooltip class="betshare-tooltip bet-total" *ngIf="betError?.errorCode !== MTP_RESTRICTION && bet.betShare" [activatedOn]="'click hover'" [position]="'top'" tooltipTitle="BET TOTAL">
            <span tooltip-toggle>
                <i class="icon--info" [cduxLogClick]="eventClickType.TOOLTIP_BET_SHARE_BET_TOTAL"></i>
            </span>
            <span tooltip-content>
                <p> {{ 'bet-share-total' | cduxTranslate:'bet-share-tooltips' }} </p>
                <p> {{ 'bet-share-cancel' | cduxTranslate:'bet-share-tooltips' }} </p>
            </span>
        </cdux-tooltip>
    </div>
    <div class="bet-slip__function-row" *ngIf="betError?.errorCode !== MTP_RESTRICTION">
        <div class="bet-slip__function-row-icons">
            <i class="icon-bet-slip delete" [cduxLogClick]="eventClickType.DELETE_BET" (click)="deleteBet()"></i>
            <i class="icon-bet-slip edit" *ngIf="!isBetPad" [class.is-inactive]="betError && betError.disableEdit" [cduxLogClick]="eventClickType.EDIT_BET" (click)="editBet()"></i>
            <i class="icon-bet-slip copy" [class.is-inactive]="betError && betError.disableCopy" [cduxLogClick]="eventClickType.DUPLICATE_BET" (click)="copyBet()"></i>
            <i class="icon-bet-slip betshare" *ngIf="betShareEnabled && allowsBetShare && !isBetPad" [ngClass]="{'is-active': bet.betShare && !(betError && betError.disableSubmit), 'is-inactive' : betError && betError.disableSubmit, 'has-share-row': showBetShareTooltip}"
                (click)="openBetShare()" [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_BUTTON">
            </i>
            <i class="icon-bet-slip conditional" *ngIf="conditionalWageringEnabled && !isBetPad" [ngClass]="{'is-active': bet.conditional && !(betError && betError.disableSubmit), 'is-inactive' : betError && betError.disableSubmit}" (click)="toggleConditionalWager()" [cduxLogClick]="eventClickType.CONDITIONAL_WAGERING"></i>
        </div>
        <div class="bet-slip__bet-share-row" *ngIf="showBetShareTooltip && allowsBetShare && !externallySubmitted && !isBetPad" (click)="openBetShare()">
            <div class="bet-share-message">Share and split this bet with your friends!</div>
            <i class="icon--close-light" (click)="hideTooltip()" [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_TOOLTIP_CLOSE"></i>
        </div>
        <div class="bet-slip__function-row-submit">
            <div class="btn-bet-slip-submit" *ngIf="isButtonDisabled">
                <div class="loader-block button-loader standard-color">
                    <div class="loader-dots">
                        <i class="dots-animated"></i>
                        <i class="dots-animated"></i>
                        <i class="dots-animated"></i>
                        <i class="dots-animated"></i>
                    </div>
                </div>
            </div>
            <div class="btn-bet-slip-submit" [cduxLogClick]="eventClickType.SUBMIT_BET"
                [class.is-inactive]="externallySubmitted || (betError && (betError.isPermanent || betError.disableSubmit || betError.errorCode))"
                (click)="submitBet()" *ngIf="!isButtonDisabled">Submit
            </div>
        </div>
    </div>
    <!-- Hide the tooltip via externallySubmitted if the Submit All button is clicked-->
</div>
