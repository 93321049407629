import {
    ChangeDetectorRef,
    Component,
    Input,
    Optional,
    Self,
    ChangeDetectionStrategy,
    OnDestroy,
    ComponentRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
    ModalService,
    CduxMediaToggleService,
    ModalRef,
    DropupModalConfig,
    DropupPrebuiltComponent
} from '@cdux/ng-platform/web';
import { BetCustomAmountComponent } from '../bet-custom-amount/bet-custom-amount.component';
import { distinctUntilChanged, take } from 'rxjs/operators';
import {
    Bet,
    EventClickType,
    enumBetModifier,
    ISelectedBetAmount,
    ITrackBasic,
    BasicBetType,
    MultiRaceExoticBetType,
    ITrack,
    IPoolType
} from '@cdux/ng-common';
import { AbstractDropupSelectComponent } from '@cdux/ng-fragments';
import { BetSlipBusinessService } from '../../services/bet-slip.business.service';
import { Subscription } from 'rxjs';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { BetPadBetNavBusinessService } from '../../../../bet-pad/program/services/bet-pad-betnav.business.service';

@Component({
    selector: 'cdux-bet-nav-amount',
    templateUrl: './bet-nav-amount.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetNavAmountComponent extends AbstractDropupSelectComponent<ISelectedBetAmount, BetNavAmountComponent> implements OnDestroy {

    @Input() selectedPoolType: (BasicBetType | MultiRaceExoticBetType);
    @Input() selectedTrackRace: ITrackBasic;
    @Input() selectedBetModifier: enumBetModifier;
    @Input() hideOtherAmount: boolean;
    /**
     * Header text for the dropup.
     * @type {string}
     */
    public dropupHeaderText = 'Bet Amount';

    /**
     * want to show the custom amount modal
     */
    private _modalRef: ModalRef<BetCustomAmountComponent> | ModalRef<DropupPrebuiltComponent<BetCustomAmountComponent>>;
    private _subs: Subscription[] = [];
    private _mediaModalSub: Subscription;

    /**
     * Overwrites getter of AbstractDropupSelectComponent
     * to bind a function to open a custom amount modal to the template on responsive
     * @returns {any}
     */
    public get context(): any {
        return {
            list: this._list,
            value: this.value,
            availability: this.availability,
            hideOtherAmount: this.hideOtherAmount,
            openBetCustomAmountModal: this.openBetCustomAmountModal.bind(this)
        };
    }

    /**
     * Overwrites setter of AbstractDropupSelectComponent
     * due to custom modal bound to getter
     * both getter and setter must be overwritten
     * @param context
     */
    public set context(context: any) {
        Object.keys(context)
            .forEach(key => this[key] = context[key]);
        this._changeDetector.detectChanges();
    }

    public eventClickType = EventClickType;

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetector
     * @param {CduxMediaToggleService} _mediaQueryService
     * @param {ModalService} _modalService
     * @param {NgControl} _control
     */
    constructor(
        _changeDetector: ChangeDetectorRef,
         _mediaQueryService: CduxMediaToggleService,
         _modalService: ModalService,
        private _betSlipService: BetSlipBusinessService,
        @Self() @Optional() _control: NgControl,
        private _eventTrackingService: EventTrackingService
    ) {
        super(
            _changeDetector,
            BetNavAmountComponent,
            _mediaQueryService,
            _modalService,
            _control
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.clearModal(true);
    }

    /**
     * open a modal with bet custom amount component
     */
    public openBetCustomAmountModal(): void {
        if ( this.selectedTrackRace) {
            const currentBet = Bet.fromBetlike(this._betSlipService.currentBet);
            currentBet.betSubtype = BetPadBetNavBusinessService.mapModifierToBetSubtype(this.selectedBetModifier);
            currentBet.track =  this.selectedTrackRace as ITrack;
            currentBet.poolType = {
                Code: this.selectedPoolType && this.selectedPoolType.code ? this.selectedPoolType.code : 'WN'
            } as IPoolType;
            this._betSlipService.currentBet = currentBet;
        }

        if (!this._mediaModalSub) {
            try {
                // monitors if modal should change based on a change in screen size
                this._mediaModalSub = this._mediaQueryService.registerQuery('desk-to-phone')
                    .pipe(distinctUntilChanged()).subscribe(largerThanPhone => {
                        // preserve input amount if it exists
                        let value;
                        if (!!this._modalRef) {
                            let component;
                            if (largerThanPhone) {
                                const dropUp = (this._modalRef as ModalRef<DropupPrebuiltComponent<BetCustomAmountComponent>>);
                                component = (dropUp.componentInstance.componentRef as ComponentRef<BetCustomAmountComponent>).instance;
                            } else {
                                component = this._modalRef.componentInstance as BetCustomAmountComponent;
                            }
                            if (!!component) {
                                value = component.value;
                            }
                            // unsubscribe any modal related subscriptions and clear modal before switching
                            this.clearModal();
                        }

                        // if not a phone, load custom amount component in modal, else use dropup component for phone
                        if (largerThanPhone) {
                            this._modalRef = this._modalService.open(BetCustomAmountComponent, {
                                hasBackdrop: true,
                                position: {
                                    top: this.calculateTop()
                                },
                                overflow: 'hidden',
                                width: 'auto'
                            });

                            const c = this._modalRef.componentInstance as BetCustomAmountComponent;
                            if (!!value) {
                                c.value = value;
                            }
                            this._subs.push(c.betCustomAmount.subscribe((betAmount: ISelectedBetAmount) => {
                                this.value = betAmount;
                                const bet = Bet.fromBetlike(this._betSlipService.currentBet);
                                bet.amount = betAmount;
                                this._betSlipService.currentBet = bet;
                            }));
                        } else {
                            const modalConfig = new DropupModalConfig();
                            modalConfig.width = 'auto';
                            modalConfig.context = {
                                headerText: 'Edit Amount',
                                component: BetCustomAmountComponent,
                                attachedCallback: (c) => {
                                    if (!!value) {
                                        c.instance.value = value;
                                    }
                                    this._subs.push(c.instance.betCustomAmount.subscribe((betAmount: ISelectedBetAmount) => {
                                        this.value = betAmount;
                                        const bet = Bet.fromBetlike(this._betSlipService.currentBet);
                                        bet.amount = betAmount;
                                        this._betSlipService.currentBet = bet;
                                    }));
                                }
                            };

                            this._modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig) as ModalRef<DropupPrebuiltComponent<BetCustomAmountComponent>>;
                            // DropupPrebuiltComponent has own close button functionality
                            this._subs.push(this._modalRef.componentInstance.close.pipe(
                                take(1)
                            ).subscribe(() => {
                                this._modalRef.close();
                                // send betpad "Other" amount close event:
                                this._eventTrackingService.logClickEvent(
                                    this.eventClickType.BET_CUSTOM_AMOUNT_CLOSE
                                );
                            }));
                        }

                        // final cleanup after modal is closed, will fire no matter how modal is closed
                        this._subs.push(this._modalRef.afterClosed.pipe(
                            take(1)
                        ).subscribe(() => {
                            this.clearModal(true);
                        }));
                    });
            } catch {
                this.clearModal(true);
            }
        }
    }

    private clearModal(closeMediaSub?: boolean): void {
        if (!!this._modalRef) {
            this._modalRef.close();
            this._modalRef = null;
        }
        this._unsubscribeAll();
        if (closeMediaSub && !!this._mediaModalSub) {
            this._mediaModalSub.unsubscribe();
            this._mediaModalSub = null;
        }
    }

    private calculateTop(): string {
        if (this._mediaQueryService.query('tablet')) {
            // set higher on tablet, modal covered by keyboard on android devices
            return '25px';
        } else {
            return '330px';
        }
    }

    private _unsubscribeAll(): void {
        this._subs.forEach(sub => {
            if (!!sub && !sub.closed) {
                sub.unsubscribe();
            }
        });
        this._subs = [];
    }
}
